<template>
  <v-container fluid class="pb-8">
    <div>
      <v-row class="pt-0" align="center" justify="center">
        <v-col cols="8">
          <h4 class="text-center text-h4">Thank you for your order</h4>
        </v-col>
      </v-row>
      <v-row class="pt-4" align="center" justify="center">
        <v-col cols="8">
          <p class="text-center">
            A Salesperson will reach out to you shortly with a
            quote and schedule
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-4" align="center" justify="center">
        <v-col cols="8">
          <p class="text-center text-h5">
            We look forward to supporting your film!
          </p>
        </v-col>
      </v-row>
      <!-- <v-row class="pt-4" align="center" justify="center">
        <v-col cols="8">
          <p class="text-center">
            When sending your materials, please label them with:
          </p>

          <p class="text-center pt-0">The title of your project</p>
          <p class="text-center pt-0">Your name and contact info</p>
          <p class="text-center pt-0">Your customer code (to be given upon account creation)</p>
          <p class="text-center pt-0">Film processing instructions (e.g. normal, push/pull, etc)</p>
          <p class="text-center pt-0">Set to ATTN: Dariane Nabor</p>
        </v-col>
      </v-row> -->
      <v-row class="pt-4" align="center" justify="center">
        <v-col cols="8">
          <p class="text-center">2801 W Alameda Ave Burbank, CA 91505</p>
          <p class="text-center">
            Will Call hours: 7am-10pm for pick-ups &amp; drop-offs
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-6" align="center" justify="center">
        <v-col cols="6" class="d-flex justify-center">
          <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'confirmation',
  title: 'Confirmation',
  components: {},
  props: {},
  data: function () {
    return {
      currentSection: 'start',
      order: null
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    })
  },
  methods: {
    startOver () {
      this.$emit('startOver')
    }
  }
}
</script>
